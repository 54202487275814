.tiptap  {
    min-height: unset;
    p {
        margin: 0 0;
    }


}

.tiptap .fullHeight {
    height: 100%
}

.ProseMirror {
    min-height: 100px;
    line-height: 1.3;
    &.mid-height {
        min-height: 20px;
    }
    &:focus {
        outline: none;
    }
    p.is-editor-empty:first-child::before {
        color: rgba(255, 255, 255, 0.5);
        content: attr(data-placeholder);
        float: left;
        height: 0;
        pointer-events: none;
        font-family: Branding;
        font-weight: 600;
    }
}

.ProseMirror-focused {

}
